











  import { Component, Prop, Vue } from 'vue-property-decorator'
  @Component
  export default class OneSidebarHeader extends Vue {
    @Prop({
      type: String,
    })
    title?: string;
  }
